<template>

  <div class="video-content">
    <!--        {{server_url1+src}}-->
    <video
        style="width:100%;height:100%"
        :src="server_url+medialink"
        ref="myVideo"
        preload:auto
        class="swiper-slide-img"
        id="myVideo" controls controlslist="nodownload  noremoteplayback"
        oncontextmenu = "return false">
      您的浏览器不支持 video 标签。
      <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/English.vtt" srclang="en" label="English" kind="subtitles" default  />-->
      <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/Indonesian.vtt" srclang="en" label="印度语" kind="subtitles" />-->
      <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/Thai.vtt" srclang="en" label="泰文" kind="subtitles" />-->
      <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/Vietnamese.vtt" srclang="zh-cn" label="简体中文" kind="subtitles" />-->
    </video>
    <img
        src="@/assets/playbtn.png"
        alt=""
        @click="operateVideo"
        class="pointer operate-btn"
        :class="{ 'fade-out': videoState }"
        v-show="videoState"
    />
  </div>
</template>

<script>

import Config from "@/config";
import Storage from "@/common/storage";
export default {
  components: {},
  name: "category_list",
  props: [],
  data() {
    return {
      medialink: "",
      server_url: Config.data.server_url,
      server_url1: Config.data.server_url1,

      videoState: true,
      mediasub: false,
      videoOptions: {
        autoplay: true,

        controls: true,
        sources: [
          {
            src:"",
            type: "video/mp4"
          }
        ]
      },
    }
  },
  created() {
    console.log(this.$route.query.medialink)
    // this.medialink = this.$route.query.medialink
    this.mediainfo(this.$route.query.medialink)
    this.mediasub = this.$route.query.sub
  },
  mounted() {

  },
  methods: {
    async mediainfo(code) {
      console.log("请求code", code);
      this.loading = true;
      let result = await this.$get("/api/customer/videos/play", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          video_code: code,
        }
      });
      this.medialink=result
      console.log("geturl" ,result);

      this.loading = false;
    },
    // 操作视频播放、暂停
    operateVideo() {
      if (!this.medialink) {
        // this.$message({ message: "暂无视频资源，请查看其他视频！" });
        return false;
      }
      if (this.$refs.myVideo.paused) {
        this.$refs.myVideo.play();
        this.videoState = true;
      } else {
        this.$refs.myVideo.pause();
        this.videoState = false;
      }
    },



  }
}
</script>

<style lang="scss" scoped>
body{
  overflow-y: hidden!important;
}
.video-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .operate-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    cursor: pointer;
  }
  .operate-btn:hover {
    opacity: 0.8;
  }
  .fade-out {
    opacity: 0;
  }
}

</style>